import { useRouter } from 'next/router'

import { NotFoundError } from 'utils/customError'

const PageNotFound = () => {
  const router = useRouter()
  const ThrowError = () => {
    throw new NotFoundError(router.asPath, new Error('Page is not found'))
  }

  return <ThrowError />
}

export default PageNotFound
